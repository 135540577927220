import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import router from "../router";
import qs from "qs";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    navIndex: 0,

    rootCategory: null,

    areaSummaryCategoryIndex: 0, // 全区概况当前子栏目

    categorySlogans: {
      全区概况: "促发展·促融合",
      政策宣传: "提升服务质量",
      数字楼宇: "提升营商环境",
      融合发展: "提升转型速度",
      智慧楼宇: "提升营商环境",
    },

    uiIp: null,
    uiPort: null,
  },

  getters: {
    getRootCategory: () => (category) => {
      let root = category;
      while (root.parent) {
        root = root.parent;
      }
      return root;
    },
  },

  mutations: {
    setNavIndex(state, index) {
      state.navIndex = index;
    },

    setRootCategory(state, category) {
      state.rootCategory = category;
    },

    setAreaSummaryCategoryIndex(state, index) {
      state.areaSummaryCategoryIndex = index;
    },

    setUiIp(state, ip) {
      state.uiIp = ip;
    },

    setUiPort(state, port) {
      state.uiPort = port;
    },
  },

  actions: {
    getUiProtocol: async function ({}) {
      let res = await Vue.axios.get(`/api/front-ends/ui/protocol`);
      return res.data;
    },

    getUiIp: async function ({ state, commit }) {
      let res = await Vue.axios.get(`/api/front-ends/ui/ip`);
      return res.data;
    },

    getUiPort: async function ({ state, commit }) {
      let res = await Vue.axios.get(`/api/front-ends/ui/port`);
      return res.data;
    },

    onCategoryClick({}, category) {
      if (category.module === "Link" && category.href) {
        if (category.outerHref) {
          window.href = category.href;
        } else {
          router.push(category.href).catch(() => {});
        }
        return;
      }

      if (category.module === "Picture") {
        router
          .push({
            name: "ImageArticleList",
            params: {
              categoryId: category.id,
            },
          })
          .catch(() => {});
        return;
      }

      router
        .push({
          name: "ArticleList",
          params: {
            categoryId: category.id,
          },
        })
        .catch(() => {});
    },

    findCategory: async function ({}, id) {
      let res = await Vue.axios.get(`/api/categories/${id}`);
      return res.data;
    },

    findCategories: async function ({}, { parentId }) {
      let res,
        page = 0,
        datas = [];
      do {
        res = await Vue.axios.get(`/api/categories`, {
          params: {
            parentId,
            inList: true,
            page,
          },
        });
        page++;
        datas.push(...res.data.content);
      } while (!res.data.last);
      return datas;
    },

    queryCategoryInNamePaths: async function ({}, namePaths) {
      let res = await Vue.axios.get(
        `/api/categories/find/name-path/${namePaths}`
      );
      return res.data;
    },

    findArticle: async function ({}, id) {
      let res = await Vue.axios.get(`/api/articles/${id}`);
      return res.data;
    },

    findArticleData: async function ({}, id) {
      let res = await Vue.axios.get(`/api/article-datas/${id}`);
      return res.data;
    },

    findArticles: async function (
      {},
      { categoryId, parentIds, page = 1, size = 10 }
    ) {
      let res = await Vue.axios.get(`/api/articles`, {
        params: {
          categoryId,
          parentIds,
          page: page - 1,
          size,
          sort: ["weight,desc", "createTime,desc"],
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });
      return res.data;
    },

    queryStreets: async function () {
      let res = await Vue.axios.get(`/api/enums/scaled-enterprise-streets`);
      return res.data;
    },

    queryTypes: async function () {
      let res = await Vue.axios.get(`/api/enums/scaled-enterprise-types`);
      return res.data;
    },

    findBuilding: async function ({}, id) {
      let res = await Vue.axios.get(`/api/buildings/${id}`);
      return res.data;
    },

    findBuildingImages: async function (
      {},
      { buildingIds } = { buildingIds: [] }
    ) {
      const promises = buildingIds.map((id) => {
        return new Promise((resolve) => {
          Vue.axios.get(`/api/building-images/building/${id}`).then((res) => {
            const buildingImageEntity = res.data.map((item) => {
              return Object.assign(item, {
                imgArray: JSON.parse(item.imgs),
              });
            });
            resolve(buildingImageEntity);
          });
        });
      });

      const datas = await Promise.all(promises);
      return datas.flat();
    },

    findSupplyChainProject: async function ({}, id) {
      let res = await Vue.axios.get(`/api/sc-projects/${id}`);
      const entity = res.data;

      res = await Vue.axios.get(`/api/sc-project-datas/${id}`);
      const entityData = res.data;

      return Object.assign({}, entity, {
        data: entityData,
      });
    },
  },

  modules: {},

  plugins: [vuexLocal.plugin],
});
