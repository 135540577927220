import Vue from "vue";
import VueRouter from "vue-router";
// import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../views/frames/CommonFrame.vue"),
    children: [
      {
        path: "",
        component: () => import("../views/frames/FirstFrame.vue"),
        // component: { template: "<router-view/>" },
        children: [
          {
            path: "",
            name: "Index",
            component: () => import("../views/IndexView.vue"),
          },

          {
            path: "guest-books/add",
            name: "GuestBookAdd",
            component: () => import("../views/GuestBook.vue"),
          },

          {
            path: "scaled-enterprise-registrations/add",
            name: "ScaledEnterpriseRegistrationAdd",
            component: () =>
              import("../views/ScaledEnterpriseRegistration.vue"),
          },

          {
            path: "login-guide",
            name: "LoginGuide",
            component: () => import("../views/LoginGuide.vue"),
          },
        ],
      },

      {
        path: "articles",
        component: () => import("../views/frames/SecondaryCategoryFrame.vue"),
        children: [
          {
            path: "category/:categoryId",
            name: "ArticleList",
            component: () => import("../views/articles/ArticleList.vue"),
          },
          {
            path: "image/category/:categoryId",
            name: "ImageArticleList",
            component: () => import("../views/articles/ImageArticleList.vue"),
          },
          {
            path: ":id",
            name: "ArticleView",
            component: () => import("../views/articles/ArticleView.vue"),
          },
        ],
      },

      {
        path: "dc",
        component: { template: "<router-view/>" },
        children: [
          {
            path: "service-enterprises",
            name: "ServiceEnterprises",
            component: () =>
              import("../views/secondaryCategories/ServiceForEnterprises.vue"),
          },

          {
            path: "scaled-enterprises",
            component: { template: "<router-view/>" },
            children: [
              {
                path: "",
                name: "ScaledEnterpriseList",
                component: () =>
                  import("../views/scaledEnterprises/ScaledEnterpriseList.vue"),
              },
              {
                path: ":id",
                name: "ScaledEnterpriseView",
                component: () =>
                  import("../views/scaledEnterprises/ScaledEnterpriseView.vue"),
              },
            ],
          },

          {
            path: "buildings",
            component: { template: "<router-view/>" },
            children: [
              {
                path: "",
                name: "BuildingList",
                component: () => import("../views/buildings/BuildingList.vue"),
              },
              {
                path: ":id",
                name: "BuildingView",
                component: () => import("../views/buildings/BuildingView.vue"),
              },
            ],
          },

          {
            path: "smart-buildings",
            name: "SmartBuildings",
            component: () =>
              import(
                "../views/secondaryCategories/smartBuildings/SmartBuildings.vue"
              ),
          },
        ],
      },

      {
        path: "cc",
        component: () => import("../views/frames/SecondaryCategoryFrame.vue"),
        children: [
          /** 全区概况二级栏目特殊页 */
          {
            path: "area-summary",
            component: { template: "<router-view/>" },
            children: [
              {
                path: "",
                name: "AreaSummary",
                component: () =>
                  import(
                    "../views/secondaryCategories/areaSummary/AreaSummary.vue"
                  ),
              },

              {
                path: "dyq-desc",
                name: "DyqDesc",
                component: () =>
                  import(
                    "../views/secondaryCategories/areaSummary/DyqDesc.vue"
                  ),
              },

              /** 全区概况两篇文章单独查看页 */
              {
                path: "articles",
                component: { template: "<router-view/>" },
                children: [
                  {
                    path: ":index",
                    name: "AreaSummaryArticleView",
                    component: () =>
                      import(
                        "../views/secondaryCategories/areaSummary/AreaSummaryArticleView.vue"
                      ),
                  },
                ],
              },

              /** 重点产业介绍 */
              {
                path: "focus-industries",
                name: "FocusIndustries",
                component: () =>
                  import(
                    "../views/secondaryCategories/areaSummary/FocusIndustries.vue"
                  ),
              },
            ],
          },

          /** 政策法规二级栏目特殊页 */
          {
            path: "policy",
            component: { template: "<router-view/>" },
            children: [
              {
                path: "",
                name: "PolicyRegulations1",
                component: () =>
                  import("../views/secondaryCategories/PolicyRegulations.vue"),
              },
              {
                path: "list",
                name: "PolicyRegulations2",
                component: () => import("../views/articles/ArticleList.vue"),
              },
            ],
          },

          /** 融合发展 */
          {
            path: "integrating",
            component: { template: "<router-view/>" },
            children: [
              {
                path: "",
                name: "IntergratingIndex",
                component: () =>
                  import("../views/secondaryCategories/IntergratingIndex.vue"),
              },
            ],
          },

          /** 会员名录 */
          {
            path: "associations",
            name: "AssociationList",
            component: () =>
              import("../views/associations/AssociationList.vue"),
          },

          {
            path: "supplies-types",
            name: "SuppliesTypeList",
            component: () =>
              import("../views/suppliesTypes/SuppliesTypeList.vue"),
          },

          /** 供应链企业明细 */
          {
            path: "sc-enterprise/:id",
            name: "SupplyChainEnterpriseView",
            component: () =>
              import(
                "../views/supplyChainEnterprises/SupplyChainEnterpriseView.vue"
              ),
          },

          /** 供应链企业投资项目明细 */
          {
            path: "sc-project/:id",
            name: "SupplyChainProjectView",
            component: () =>
              import("../views/supplyChainProjects/SupplyChainProjectView.vue"),
          },

          {
            path: "project-intro/:id",
            name: "ProjectIntroView",
            component: () =>
              import("../views/projectIntros/ProjectIntroView.vue"),
          },

          /** 党群 组织架构 */
          {
            path: "org-charts",
            name: "OrgChart",
            component: () =>
              import(
                "../views/secondaryCategories/smartBuildings/OrgChart.vue"
              ),
          },

          /** 党群 党建联盟 */
          {
            path: "coalition-charts",
            name: "CoalitionChart",
            component: () =>
              import(
                "../views/secondaryCategories/smartBuildings/CoalitionChart.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/mindmap",
    name: "Mindmap",
    component: () =>
      import("../views/secondaryCategories/areaSummary/Mindmap.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      // 滚动到指定的锚点
      return { selector: to.hash };
    } else {
      // 没有指定锚点则返回屏幕最上方
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    //判断是否有标题
    document.title = to.meta.title;
  } else {
    document.title = "东营区数字服务平台";
  }
  next();
});

export default router;
