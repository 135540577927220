import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import axios from "axios";
import VueAxios from "vue-axios";

const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

Vue.use(VueAxios, instance);

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

/** 高德地图 */
import AmapVue from "@amap/amap-vue";
window._AMapSecurityConfig = {
  securityJsCode: "74e1afce1516f7202bce13388166f948",
};

AmapVue.config.version = "2.0"; // 默认2.0，这里可以不修改
// AmapVue.config.key = "64a58f50296868ab377514e5729f8e9c";
AmapVue.config.key = "17ff7e2f4c00a989de70ebdfe90c2975";
AmapVue.config.plugins = [
  "AMap.ToolBar",
  "AMap.PlaceSearch",
  "AMap.MoveAnimation",
  "AMap.AutoComplete",
  // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
];
Vue.use(AmapVue);
/** 高德地图 */

/**
 * 滚动组件
 */
import DynamicMarquee from "vue-dynamic-marquee";
Vue.component("dynamic-marquee", DynamicMarquee);
/**
 * 滚动组件
 */

Date.prototype.Format = function (fmt) {
  //author: meizz
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
